<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Arrange the elements listed below from highest down to lowest electronegativity.
      </p>

      <drag-to-rank-input
        v-model="inputs.studentAnswers"
        :items="itemsShuffled"
        class="mb-0"
        style="max-width: 512px"
        prepend-text="Most electronegative"
        append-text="Least electronegative"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA7_Q16',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      items: ['Sulfur', 'Chlorine', 'Phosphorus'],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    itemsShuffled() {
      return seededShuffle(this.items, this.seed);
    },
  },
};
</script>
